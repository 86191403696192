<template>
  <div class="recruitmentss">
    <!-- 职位列表 -->
    <div style="background: #f7f7f7">
      <div class="postlist" v-for="item in list" :key="item.index">
        <div>
          <!-- 一 职位 薪资 -->
          <div class="listtitle">
            <span>申请职位:{{ item.JTitle }}</span>
            <span style="color: #666666;font-size:14px">申请人: {{ item.Name }}</span>
          </div>
          <!-- 四 招募人 地址 -->
          <div class="listfore">
            <div>
              {{ item.DeliverTime }}
            </div>
          </div>
          <div class="RedeliverOn clearfix">
            <span style="color:#188EFB;padding-left:15px;float:left" class="clearfix">
              <!-- <van-icon name="manager" /> -->
              <!-- <van-icon name="manager-o" style="font-size:16px" /> -->
              <span v-if="item.StatusDesc" style="font-size:14px">{{ item.StatusDesc }}</span>
              <span v-if="item.EliminateReason" style="color:red;font-size:14px">已{{item.EliminateReason}}</span>
            </span>
            <span style="float:right;margin-right:15px;" class="clearfix">
               <van-button type="info" round @click="todetails(item.ReId)"
              >查看简历</van-button
            >
            <van-button type="primary" plain round @click="AddCallList(item.RDId)"
              >加入候选人</van-button
            >
            <van-button type="danger" plain round @click="Eliminate(item.RDId)"
              >淘 汰</van-button
            >
            </span>
          </div>
        </div>
        <!-- </router-link> -->
      </div>
    </div>
    <van-popup
      v-model="EliminateShow"
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <textarea
        class="Reasoninput"
        v-model="Eliminatefrom.Reason"
        placeholder="请输入淘汰原因"
      />
      <div class="reason">
        <van-button round type="info" size="small" @click="Eliminates()"
          >提 交</van-button
        >
      </div>
    </van-popup>
    <div class="kong" v-if="kongshow">还没有收到简历~</div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import wx from "weixin-js-sdk";
import { getaccToken, setaccToken } from "@/utils/auth";
import {
  RE_GetJobReDeliverList,
  RE_AddCallList,
  RE_Eliminate,
  RE_GetReDeliverPage,
} from "@/api/REplat";
export default {
  data() {
    return {
      search: "", //搜索
      itemmark: "", //职位详情
      listdata: {
        page:1,
        limit:100,
      },
      jId: 0,
      active: 0,
      list: [], //求职者列表
      lists: [], //职位列表
      kongshow:false,
      EliminateShow: false,
      Eliminatefrom: {
        Reason: "", //淘汰原因
      },
    };
  },
  created() {
    this.GetJobPage();
  },
  methods: {
    onSearch() {},
    //小程序跳转到小程序
    gotominiProgram(name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 获取求职者列表
    GetJobPage() {
      this.listdata.accToken = getaccToken();
      RE_GetReDeliverPage(this.listdata).then((res) => {
        console.log(res);
        if(res.data.code == 0){
          if(res.data.data.length == 0){
            this.kongshow = true;
            this.list = [];
          }else{
            this.list = res.data.data;
          }
        }else{
          Dialog.alert({
                message: res.data.msg,
              }).then(() => {
                // on close
              });
        }
        
      });
    },
    // 加入招聘流程
    AddCallList(row) {
      Dialog.confirm({
        message: "是否将求职者加入招聘流程!",
      })
        .then(() => {
          RE_AddCallList({ accToken: getaccToken(), RDId: row }).then((res) => {
            if (res.data.code == 0) {
              Dialog.alert({
                message: "加入成功!",
              }).then(() => {
                // on close
              });
            } else {
              Dialog.alert({
                message: res.data.msg,
              }).then(() => {
                // on close
              });
            }
          });
        })
        .catch(() => {
          return false;
        });
    },
    Eliminate(row) {
      this.Eliminatefrom.accToken = getaccToken();
      this.Eliminatefrom.RDId = row;
      this.EliminateShow = true;
    },
    Eliminates(row) {
      RE_Eliminate(this.Eliminatefrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            message: "提交成功!",
          }).then(() => {
            this.Eliminatefrom.Reason = "";
          });
        } else {
          Dialog.alert({
            message: res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    todetails(row) {
      this.$router.push({
        name: "jobResume",
        query: { ReId: row },
      });
    },
  },
};
</script>
<style scoped>
.recruitmentss {
  background: #f2f8f9;
  height: 100%;
}
.van-hairline--bottom:after {
  border-bottom-width: 0px;
}
.recruitmentss .van-nav-bar__title {
  color: #fff;
}
.recruitmentss .van-nav-bar__content {
  background: #0188fb;
}
.postlist {
  margin: 5px 0px;
  background: #fff;
}
.postlist .listtitle {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  font-size: 700;
  color: #000;
}
.postlist .listtwo {
  padding: 0px 10px;
  font-size: 12px;
}
.postlist .listtwo span {
  padding: 6px;
  background: rgb(238, 237, 237);
  margin-right: 8px;
  color: #666;
}
.postlist .listthree {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  font-size: 14px;
}
.postlist .listthree span {
  margin-right: 30px;
  color: #666;
}
.postlist .listfore {
  /* display: flex;
  flex-wrap: nowrap;
  justify-content: space-between; */
  padding: 0px 0px 15px 10px;
  font-size: 13px;
}
.postlist .listfore span {
  margin-right: 15px;
  color: #a3a7a8;
}
.van-search__action {
  margin-right: 10px !important;
  color: #fff;
}
.van-search__action:active {
  background: #0188fb;
}
.van-tabs__nav--card {
  margin: 0px !important;
  height: 40px;
}
.kong {
  text-align: center;
  background: rgb(238, 236, 236);
  width: 90%;
  margin: 20px 5%;
  height: 60px;
  line-height: 60px;
  border-radius: 15px;
}
.RedeliverOn {
  /* padding-left: 30%; */
  padding-bottom: 15px;
}
.RedeliverOn button {
  height: 30px;
  font-size: 12px;
  margin: 3px;
}
.Reasoninput {
  border-radius: 30px;
  width: 80%;
  height: 100px;
  margin: 15px 7%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  background: #ccc;
  font-size: 14px;
}
.reason {
  text-align: center;
}
.reason button {
  width: 100px;
}
/* 清除浮动*/
 .clearfix:after{/*伪元素是行内元素 正常浏览器清除浮动方法*/
        content: "";
        display: block;
        height: 0;
        clear:both;
        visibility: hidden;
    }
    .clearfix{
        *zoom: 1;/*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
    }
</style>